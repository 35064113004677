/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~animate.css/animate.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~lightgallery/scss/lightgallery";
@import "../node_modules/css-file-icons/build/css-file-icons.css";
@import '../node_modules/intl-tel-input/build/css/intlTelInput.css';

/* ------------------------- Custom CSS ------------------------- */
body {
  overflow-x: hidden;
}
a {
  cursor: pointer;
}
.bg-light-orange {
  background-color: #faf6ef;
}
.bg-dark-blue {
  background: #2f4858;
}
.bg-light-orange {
  background: #faf6ef;
}
.color-border {
  border-color: #d9d9d9 !important;
}
.bg-light-gray {
  background: #fcfcfc;
}
.text-none,
.none {
  display: none !important;
}
.pt-lg-80 {
  padding: 80px !important;
}
.listing li {
  color: #fff;
  margin-bottom: 15px;
}
.w-140px {
  width: 141px;
}
.landing-header .menu .menu-link.active {
  background-color: rgba(255, 255, 255, 1);
}
.h-auto {
  height: auto;
}
.py-20px {
  padding: 20px;
}
.mb-minus-80 {
  margin-top: -114px;
  padding-bottom: 5px;
  padding-left: 20px;
  background-image: -webkit-linear-gradient(
    90deg,
    #000000,
    #00000000
  ) !important;
  // background-image: -webkit-linear-gradient( 90deg, rgb(0,0,0) 0%, rgba(0,0,0,0) 100%) !important;
  // background-image: -webkit-linear-gradient(
  //   90deg,
  //   rgb(0, 0, 0) 0%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  // background-image: -ms-linear-gradient(
  //   90deg,
  //   rgb(0, 0, 0) 0%,
  //   rgba(0, 0, 0, 0) 100%
  // );
  padding-top: 30px;
}
.light-gradient {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 0 0 0.75rem 0.75rem;
}
.h-100vh {
  min-height: 100vh !important;
}
.min-h-320px {
  min-height: 320px;
}
.border-dark-light {
  border: 4px solid #456a82;
}
.badge-right {
  position: relative;
  right: -250px;
}
.h-170px {
  min-height: 170px;
}
.w-190px {
  width: 190px;
}
.stepper.stepper-pills .stepper-item.current .stepper-icon {
  background-color: #1f1006;
}
.shadow {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
}
.pas-1 {
  top: 20px;
  left: -50px;
}
.rounded-30 {
  border-radius: 30px;
}
.pas-2 {
  bottom: -100px;
  right: -27px;
}
.pas-3 {
  bottom: -40px;
  right: -100px;
}
.widget-hover {
  position: relative;
}
.widget-hover:after {
  display: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}
.widget-hover:hover:after {
  background: var(--color-theme);
  padding: 15px;
  border-radius: 30px;
  position: absolute;
  content: "";
  width: 93%;
  height: 100%;
  top: 0px;
  transform: rotate(5deg);
  z-index: -1;
  display: block;
}
.box-border {
  border: 1px solid #ccc;
  // height: 110px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 30px;
}
.box-border img {
  width: 90%;
}
.fillter-grayscale {
  filter: grayscale(1);
  opacity: 0.3;
}
.box-border:hover {
  border: 1px solid #eb9153;
  box-shadow: 0px 4px 4px rgba(235, 145, 83, 0.15);
}
.box-border:hover .fillter-grayscale {
  filter: none;
  opacity: 1;
}
.rounded-100 {
  border-radius: 200px !important;
  width: 100px;
  height: 100px;
}
.rounded-100 img {
  width: 150%;
}
.tns .tns-nav {
  position: relative;
  bottom: -176px;
}
.tns.tns-default {
  position: relative;
  padding-bottom: 50px;
}
.tns.tns-default .tns-outer {
  margin: 0px !important;
}
.tns .tns-nav button {
  width: 14px;
  height: 14px;
  background-color: #eff2f5;
  border-radius: 1rem;
}
.tns .tns-nav button.tns-nav-active {
  background-color: #000;
}
.camera {
  height: 600px;
  position: relative;
  border-radius: 10px;
  padding: 0%;
}

.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
}

.camera_links {
  position: absolute;
  top: 20px;
  right: 20px;
}
.investor-slide .tns-nav {
  bottom: -390px;
}
.testimonial-img {
  position: relative;
  text-align: center;
}
// .testimonial-img:after {
//   content: "";
//   position: absolute;
//   background: url(assets/images/symbol.svg) no-repeat;
//   width: 80%;
//   height: 80%;
//   left: -90px;
//   z-index: -1;
// }
.testimonial-img img {
  border-radius: 100%;
}
.carousel.carousel-custom
  .carousel-indicators.carousel-indicators-bullet
  li:after {
  background: rgba(255, 255, 255, 0.5);
}
.carousel.carousel-custom
  .carousel-indicators.carousel-indicators-bullet
  li.active:after {
  background-color: #fff;
}
.float-left {
  float: left;
}
.right {
  float: right;
}
div#mce-responses {
  position: absolute;
  top: 60px !important;
  width: 100% !important;
}
.mc-field-group {
  padding-bottom: 0 !important;
  min-height: auto !important;
}
.social_icon {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 100px;
}
.social_icon:hover {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.tab_links {
  color: #000;
}
.tab_links.active,
.tab_links.active .fa {
  color: var(--color-theme) !important;
}
.currentprimary {
  fill: var(--color-theme) !important;
}
@media (max-width: 767px) {
  .pt-lg-80 {
    padding: 2.5rem !important;
  }
  .w-140px {
    width: 84px;
  }
  .mb-minus-80 {
    margin-top: -73px;
  }
  .badge-right {
    right: 0;
  }
  .widget-hover:hover:after {
    display: none;
  }
  .w-450px {
    width: 100% !important;
  }
  .pas-2 {
    bottom: -88px;
    right: -27px;
    width: 230px !important;
  }
  .pas-1 {
    top: -50px;
    left: 0px;
  }
  .mt-small {
    margin-top: 80px;
  }
  .tns .tns-nav {
    bottom: -170px;
  }
  .mobile-center {
    text-align: center !important;
  }
  .mb-mt-50 {
    margin-top: 70px;
    padding: 0px !important;
  }
  .mb-mt-120 {
    margin-top: 120px;
    padding: 0px !important;
  }
}

.ql-toolbar {
  border: 1px solid #e4e6ef !important;
}

.ql-container {
  border: 1px solid #e4e6ef !important;
}

.avatar-content {
  color: var(--color-theme) !important;
}

.span-error-cls {
  line-height: 40px;
  margin-left: 5px;
  color: #dc3545;
}

.margin-left-8 {
  margin-left: 8px;
}

.form-ng-select {
  .ng-select-container {
    border: none;
    background-color: unset;
    color: unset;
    min-height: 22px !important;
    height: 25px !important;

    &:hover {
      box-shadow: none !important;
    }
    .ng-value-container {
      padding-left: 0px !important;
      .ng-input {
        padding: 0 !important;
      }
    }
  }
}

.ng-dropdown-panel {
  z-index: 1100 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: none !important;
  box-shadow: none !important;
}
.lottie-container {
  svg {
    transform: scale(2) !important;
  }
}

#kt_toolbar {
  padding-top: 0px !important;
  background: white;
}

#kt_toolbar_container {
  padding: 15px 30px !important;
  min-height: 70px;
}

.bottom-20px {
  bottom: 20px !important;
}

.bottom-15 {
  bottom: 15% !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.w-35 {
  width: 35%;
}

.z-index-101 {
  z-index: 101 !important;
}

.top-10 {
  top: 10% !important;
}

.start-85 {
  left: 85% !important;
}

.start-92 {
  left: 92% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-40 {
  width: 40% !important;
}

.me-minus-25 {
  right: -25px !important;
}

@media only screen and (max-width: 767px) {
  .card-footer {
    text-align: center;
  }
}

@media only screen and (min-width: 767px) {
  .w-md-40 {
    width: 40% !important;
  }

  .d-md-none {
    display: none !important;
  }
}

.circle-gradient {
  &.active {
    background: linear-gradient(90deg, #feb47b 0, #ff7e5f 100%) no-repeat
      padding-box;

    h3 {
      display: inline-block;
      color: #fff;
    }
    .black-img {
      filter: invert(1);
    }
  }
}



.col-auto {
  flex: 0 0 auto;
  width: auto;
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  // TOFO
  .fs-md-9 {
    font-size: 0.75rem !important;
  }
  .fs-md-5 {
    font-size: 1.15rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }
}
.fs-10 {
  font-size: 0.5rem !important;
}
.fs-9 {
  font-size: 0.75rem !important;
}


.end-10px {
  right: 10px;
}

.top-10px {
  top: 10px;
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0rem;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0rem;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0rem;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0rem;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.w-20 {
  width: 22% !important;
}
.w-25 {
  width: 25% !important;
}

.bi-people-fill::before {
  content: "\f4cf";
}
.bi-people::before {
  content: "\f4d0";
}
.bi-link-45deg::before {
  content: "\f470";
}
.bi-link::before {
  content: "\f471";
}
.bi-tag-fill::before {
  content: "\f5af";
}
.bi-tag::before {
  content: "\f5b0";
}
.bi-tags-fill::before {
  content: "\f5b1";
}
.bi-tags::before {
  content: "\f5b2";
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}
.btn.btn-text-primary {
  color: var(--color-theme);
}
.mt-8 {
  margin-top: 2rem !important;
}

.w-max-content {
  width: max-content;
}

.ms-12 {
  margin-left: 3rem !important;
}
.ms-5 {
  margin-left: 1.25rem !important;
}

.badge-search-cards {
  background-color: rgb(228 230 239 / 35%);
  border-radius: 100px;
}
.h-70px {
  height: 70px !important;
}

.h-75px {
  height: 75px !important;
}

.h-80px {
  height: 80px !important;
}

.h-85px {
  height: 85px !important;
}

.h-90px {
  height: 90px !important;
}

.h-95px {
  height: 95px !important;
}
.h-100px {
  height: 100px !important;
}

.max-h-100px {
  max-height: 100px !important;
}

.h-125px {
  height: 125px !important;
}

.public-profile-logo-image {
  padding: 5px;
  border: 1px solid #ccc;
  background: #ffffff;
  // border-radius: 50%;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #50cd89;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #000000;
  background-color: #50cd89;
  border-radius: 0.75rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #50cd89;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #50cd89;
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right
    calc(0.375em + 0.375rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #50cd89;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #50cd89;
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #50cd89;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #50cd89;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(80, 205, 137, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #50cd89;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #f1416c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #000000;
  background-color: #f1416c;
  border-radius: 0.75rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #f1416c;
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #f1416c;
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right
    calc(0.375em + 0.375rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #f1416c;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #f1416c;
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #f1416c;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #f1416c;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #f1416c;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-block {
    display: block !important;
  }

  .btn-lg-lg {
    padding: 0.825rem 1.75rem;
    font-size: 1.15rem;
    border-radius: 0.625rem;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.align-items-baseline {
  align-items: baseline !important;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-theme);
  color: var(--color-theme);
  box-shadow: 9999px 0 0 -5px var(--color-theme);
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-theme);
  color: var(--color-theme);
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px var(--color-theme);
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px var(--color-theme);
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px var(--color-theme);
  }
  30% {
    box-shadow: 9984px 0 0 2px var(--color-theme);
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px var(--color-theme);
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px var(--color-theme);
  }
  30% {
    box-shadow: 9999px 0 0 2px var(--color-theme);
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px var(--color-theme);
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px var(--color-theme);
  }
  30% {
    box-shadow: 10014px 0 0 2px var(--color-theme);
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px var(--color-theme);
  }
}

.btn-outline-danger {
  color: #f1416c;
  border-color: #f1416c;
}
.btn-outline-danger:hover {
  color: #000000;
  background-color: #f1416c;
  border-color: #f1416c;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 65, 108, 0.5);
}
.border-danger {
  border-color: #f1416c !important;
}

.ms-8 {
  margin-left: 2rem !important;
}
.ms-10 {
  margin-left: 2.5rem !important;
}
.ms-15 {
  margin-left: 5.5rem !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-dark {
  border-color: #181c32 !important;
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-whatsapp::before {
  content: "\f618";
}
.my-15p {
  margin: 15% 0;
}

.my-10p {
  margin: 10% 0;
}

.bi-chat-fill::before {
  content: "\f24b";
}
.bi-person-plus-fill::before {
  content: "\f4dc";
}
.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.team-members-connection-page {
  .avatar-content {
    color: black !important;
  }
}
.bi-camera-video-fill:before {
  content: "\f21c";
}
.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye::before {
  content: "\f341";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}
.bi-person-fill::before {
  content: "\f4da";
}
.corporate-avtar {
  .avatar-content {
    color: #fff !important;
  }
}

.search-card-corporate {
  .avatar-container {
    width: 100% !important;
    height: 280px !important;
  }
}

// @media (max-width: 1200px) {

//   .search-card-corporate {
//     .avatar-container {
//       height: 211px !important;
//     }
//   }

// }
@media (max-width: 768px) {
  .search-card-corporate {
    .avatar-container {
      height: 230px !important;
    }
  }
}
@media (max-width: 480px) {
  .search-card-corporate {
    .avatar-container {
      height: 180px !important;
    }
  }
}
.bi-person-check-fill::before {
  content: "\f4d5";
}
.bi-person-x-fill::before {
  content: "\f4df";
}

.status-select-hire {
  .ng-select-container {
    height: 25px !important;
  }
}
.user-type-dashboard-search{
  .ng-select-container {
    border: 0px !important;
    border-radius: 0px !important;
    cursor: pointer !important;
  }
  .ng-dropdown-panel.ng-select-bottom {
    box-shadow: 0 0.1rem 1rem 0.25rem #0000000d !important;
    border: 0 !important;
    width: 180px !important;
  }
  .ng-option{
    border-radius: 8px;
  }
  .ng-dropdown-panel {
    padding: 8px;
    border-radius: 10px;
  }
  input {
    cursor: pointer !important;
  }
}
.gy-0 {
  --bs-gutter-y: 0rem;
}

.ng-dropdown-panel-items {
  .ng-option-marked,
  .ng-option-selected {
    color: #333;
    background-color: #f2e3c7 !important;
  }
}

.multiselect-ng-dropdown {
  .ng-value {
    background-color: #f2e3c7 !important;
    color: black !important;
  }

  .ng-value-icon {
    &:hover {
      background-color: #ffe2ab !important;
    }
  }
  .ng-value-icon.left {
    border-right: 1px solid #ccc !important;
  }
}

.badge.badge-sm {
  min-width: 1.5rem;
  font-size: 0.8rem;
}


.ribbon {
  position: relative;
}
.ribbon .ribbon-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  background-color: var(--color-theme);
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  top: 50%;
  right: 0;
  transform: translateX(5px) translateY(-50%);
}
.ribbon .ribbon-label > .ribbon-inner {
  z-index: -1;
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ribbon .ribbon-label:after {
  border-color: #7a370b;
}
.ribbon-vertical .ribbon-label {
  padding: 5px 10px;
  min-width: 36px;
  min-height: 46px;
  text-align: center;
}
.ribbon.ribbon-top .ribbon-label {
  top: 0;
  transform: translateX(-15px) translateY(-4px);
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.ribbon.ribbon-bottom .ribbon-label {
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}
.ribbon.ribbon-start .ribbon-label {
  top: 50%;
  left: 0;
  right: auto;
  transform: translateX(-5px) translateY(-50%);
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.ribbon.ribbon-end .ribbon-label {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.ribbon.ribbon-clip.ribbon-start .ribbon-label {
  left: -5px;
}
.ribbon.ribbon-clip.ribbon-start .ribbon-label .ribbon-inner {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.ribbon.ribbon-clip.ribbon-start .ribbon-label .ribbon-inner:before, .ribbon.ribbon-clip.ribbon-start .ribbon-label .ribbon-inner:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: transparent !important;
  bottom: -10px;
}
.ribbon.ribbon-clip.ribbon-start .ribbon-label .ribbon-inner:before {
  border-width: 0 10px 10px 0;
  border-right-color: #181C32 !important;
  left: 0;
}
.ribbon.ribbon-clip.ribbon-end .ribbon-label {
  right: -5px;
}

.team-profile-modal {
  padding-top: 1rem !important;
  padding-bottom: 0.625rem;
}

.alert-secondary {
  color: #5b5c60;
  background-color: #fafafc;
  border-color: #f7f8fa;
}
.alert-secondary-dark {
  color: #5b5c60;
  background-color: #f1f1f1;
  border-color: #bababa;
}
.alert-secondary .alert-link {
  color: #494a4d;
}
.symbol.symbol-20px > img {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed > img {
  width: 20px;
  height: 20px;
  max-width: none;
}
.symbol.symbol-20px.symbol-2by3 .symbol-label {
  height: 20px;
  width: 30px;
}
.symbol.symbol-20px.symbol-2by3 > img {
  height: 20px;
  width: 30px;
  max-width: none;
}
.symbol.symbol-25px > img {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed > img {
  width: 25px;
  height: 25px;
  max-width: none;
}
.symbol.symbol-25px.symbol-2by3 .symbol-label {
  height: 25px;
  width: 37.5px;
}
.symbol.symbol-25px.symbol-2by3 > img {
  height: 25px;
  width: 37.5px;
  max-width: none;
}

// .carousel-indicators [data-bs-target] {
//   width: 10px;
// }
// button.carousel-control-prev {
//   background-color: #000;
//   width: 30px;
//   border-radius: 10px;
//   margin: 20px;
//   opacity: 0.1;
// }
// button.carousel-control-prev:hover {
//   opacity: 0.4;
// }
// button.carousel-control-next {
//   background-color: #000;
//   width: 30px;
//   border-radius: 10px;
//   margin: 20px;
//   opacity: 0.2;
// }
// button.carousel-control-next:hover {
//   opacity: 0.4;
// }
// .picsum-img-wrapper {
//   border: 1px solid #eee;
//   border-radius: 10px;
//   box-shadow: 0px 2px 10px #eee;
//   img {
//     border-radius: 10px;
//   }
// }
.popover-custom-width {
  max-width: 500px !important;
}
.dashboard-bg {
  background-color: #F3F6F9;
}
.bi-clock-fill::before {
  content: "\f291";
}
.bi-briefcase-fill::before {
  content: "\f1cb";
}
.bi-briefcase::before {
  content: "\f1cc";
}
.bi-megaphone::before {
  content: "\f484";
}


.fa-briefcase:before {
  content: "\f0b1";
}

.fa-map-marker:before {
  content: "\f041";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}


.bi-view-list::before {
  content: "\f605";
}
@media (min-width: 992px) {

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-0  {
    margin-top: 0rem !important;
  }
  .align-items-lg-center{
    align-items: center !important;
  }
  .align-items-lg-end{
    align-items: end !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
}
.bi-currency-dollar::before {
  content: "\f636";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.fs-10 {
  font-size: 0.60rem !important;
}

.symbol.symbol-100px > img {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed > img {
  width: 100px;
  height: 100px;
  max-width: none;
}
.symbol.symbol-100px.symbol-2by3 .symbol-label {
  height: 100px;
  width: 150px;
}
.symbol.symbol-100px.symbol-2by3 > img {
  height: 100px;
  width: 150px;
  max-width: none;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.participantsList {
  .accordion-header  {
    button {
      font-size: 16px;
      font-weight: 500 !important;
    }
  }
}
@media (min-width: 992px) {
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-0 {
    margin-left: 0rem !important;
  }

}
.recent-job-avtar {
  .avatar-content {
    color: white !important;
  }
}


:root {
  --color-theme: var(--color-theme);
  --color-secondary: #faf6ef;
}

.btn-theme {
  background: var(--color-theme);
}

.badge-theme {
  background: var(--color-theme);
}

.btn-theme:hover,
.circle-gradient:hover {
  background: var(--color-theme);
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light-orange {
  background-color: var(--color-secondary);
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--color-theme) !important;
}
.label-prefix-create-account {
  background-color: var(--color-theme) !important;
}
.btn-primary {
  background: var(--color-theme) !important;
}
.hover-primary:hover {
  background-color: var(--color-theme) !important;
  color: white;
}
.btn-primary:hover {
  background-color: var(--color-theme) !important;
}
.text-primary {
  color: var(--color-theme) !important;
}
.text-hover-primary:hover{
  color: var(--color-theme) !important;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: var(--color-theme) !important;
}
.border-primary{
  border-color: var(--color-theme) !important;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--color-theme)  !important;
}
.link-primary{
  color: var(--color-theme) !important;
}
.header {
  background: var(--color-theme) !important;
}
.alert-primary {
  background-color: #F5F5F5 !important;
  border: 1px solid #ccc !important;
  color: #3f4254 !important;
}

.symbol.symbol-40px > img {
  width: 40px;
  height: 40px;
}

.h-40px {
  height: 40px;
}
.h-50px {
  height: 50px;
}
.w-40px {
  width: 40px;
}

.symbol.symbol-40px .symbol-label,
.symbol.symbol-40px.symbol-fixed .symbol-label {
  width: 40px;
  height: 40px;
}

.symbol.symbol-40px.symbol-fixed > img {
  width: 40px;
  height: 40px;
  max-width: none;
}
#Video_Startup_form-header {
  .accordion-button {
    font-size: 15px;
    font-weight: bold;

    background-color: white !important;
    color: #000 !important;
  }
}

.Event-wrapper{
  .accordion-button::after {
    display: none;
  }
  .accordion-item{
    border: none;
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    // border-bottom: 1px solid #ededed;
    box-shadow: none;
    border: none;

    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.atcb-initialized {
  width: 100% !important;
}
.atcb-button  {
  width: 100% !important;
  max-width: 100%;
}
.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}
.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bg-form-array {
  background-color: #f5f5f5c2;
}
.bi-chevron-up::before {
  content: "\f286";
}
.border-dark {
  border-color: #181c32 !important;
}
.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.dynamic-form-section {
  // border: 1px solid #c0c0c0 !important;
  border-radius: 8px !important;
}
.dynamic-form-section-title {
  background: #fff;
  margin-top: -10px;
}
.label-prefix-create-account {
  width: 5px;
  height: 25px;
  // background: #ff7e5f;
}
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}
.verticle-devider {
  width: 1px;
  height: 20px;
  opacity: 0.40;
  background-color: currentColor;
}
.btn-gray {
  width: fit-content;
  background-color: #5a5a5a;
}
.custom-pp-modal {
  // width: 90% !important;
  // height: 90vh !important;
  // max-width: none !important;
  // margin: 0 auto !important;

  .modal-dialog {
    height: 85% !important;
    max-width: 90vw !important;
    .modal-content{
    height: 100% !important;
    }
  }

}
.banner-white-bg{
  background-color: white;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.ngx-loading-text {
  font-size: 20px !important;
  text-align: center !important;
}

.ngx-position-absolute {
  position: fixed !important;
}

.delete-loader {
  position: fixed !important;
  height: 100vh;
  width: 100%;
  z-index: 1000000000000;
  top: 0;
  left: 0;
}
label.star:hover {
  transform: none !important;
}
label.star {
  padding: 3px !important;
  font-size: 20px !important;
}
input.star:checked ~ label.star:before {
    color: var(--color-theme) !important;
}
input.star-5:checked ~ label.star:before {
  text-shadow: none !important;
}
.shadow {
  box-shadow: 0 0.5rem 1.5rem 0.5rem #00000013 !important;
}

.shadow-sm {
  box-shadow: 0 0.1rem 1rem 0.25rem #0000000d !important;
}
.shadow-xs {
  box-shadow: 0 0.2rem 0.2rem 0.05rem #0000000d !important;
}


.messageTxtStyle {
  a {
    color: white;
  }
}
.ngb-dp-header {
  .form-select {
    padding-top: 0rem !important;
  }
}
.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}
.g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }
  .rounded-2 {
    border-radius: 0.75rem !important;
  }

  .h-250px {
    height: 250px !important;
  }
  .mt-n20 {
    margin-top: -4.3rem !important;
  }
  .svg-icon.svg-icon-primary {
    color: #ed7c31;
  }



.nav.nav-pills.nav-pills-custom .show > .nav-link,
.nav.nav-pills.nav-pills-custom .nav-link {
  border: 1px dashed #E4E6EF;
  border-radius: 12px;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link .nav-icon img,
.nav.nav-pills.nav-pills-custom .nav-link .nav-icon img {
  width: 30px;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link .nav-icon img.default,
.nav.nav-pills.nav-pills-custom .nav-link .nav-icon img.default {
  display: inline-block;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link .nav-icon img.active,
.nav.nav-pills.nav-pills-custom .nav-link .nav-icon img.active {
  display: none;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link.active,
.nav.nav-pills.nav-pills-custom .nav-link.active {
  background-color: transparent;
  border: 1px solid #E4E6EF;
  transition-duration: 1ms;
  position: relative;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link.active .nav-text,
.nav.nav-pills.nav-pills-custom .nav-link.active .nav-text {
  color: #3F4254 !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link.active .bullet-custom,
.nav.nav-pills.nav-pills-custom .nav-link.active .bullet-custom {
  display: block;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link .bullet-custom,
.nav.nav-pills.nav-pills-custom .nav-link .bullet-custom {
  display: none;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link:not(:active) span:nth-child(1) {
  color: #B5B5C3;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link:not(:active) span:nth-child(2) {
  color: #3F4254;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link:hover span:nth-child(1) {
  color: white !important;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link:hover span:nth-child(2) {
  color: white !important;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link.active span:nth-child(1) {
  color: white !important;
}
.nav.nav-pills.nav-pills-custom.nav-pills-active-custom .nav-item .nav-link.active span:nth-child(2) {
  color: white !important;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ed7c31;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #c15711;
  text-decoration: none;
}
.nav-link.disabled {
  color: #7E8299;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #EFF2F5;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #EFF2F5 #EFF2F5 #EFF2F5;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #7E8299;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5E6278;
  background-color: #ffffff;
  border-color: #E4E6EF #E4E6EF #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.75rem;
}
// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//   color: #FFFFFF;
//   background-color: #ed7c31;
// }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.44375rem;
  padding-bottom: 0.44375rem;
  margin-right: 1rem;
  font-size: 1.075rem;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.075rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.ms-auto {
  margin-left: auto !important;
}
.me-n5 {
  margin-right: -1.25rem !important;
}
.end-0 {
  right: 0 !important;
}
.top-0 {
  top: 0 !important;
}


#floatingTextarea-community-feed {
  display: flex;
  flex-direction: column;

  .ql-toolbar.ql-snow {
    border: 0px !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #eff2f5 !important;
  }
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-container {
    font-size: 18px !important;
  }
  .ql-toolbar.ql-snow {
    padding: 0px 0px 8px 0px !important;
  }
  .ql-editor {
    padding: 6px !important;
  }
}
.feed-form-dashboard {
  .ql-editor {
    height: 130px !important;
  }
}
.btn-red {
  background-color: #Dc2626 !important;
}
.text-red {
  color: #Dc2626 !important;
}

.btn-red:hover {
  background-color: #e71e1e !important;
}

.alert-danger {
  color: #912741;
  background-color: #fcd9e2;
  border-color: #fbc6d3;

}

.bi-chevron-down::before {
  content: "\f282";
}
.g-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.bi-grid::before {
  content: "\f3fc";
}
.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}
.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}
.min-h-250px {
  min-height: 250px !important;
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}
.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.w-fit {
  width: fit-content;
}
.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}
.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.dashboard-common-avatar{
  .avatar-content {
    color: white !important;
  }
}

.header-nav-hover {
  &:hover {
    color: #181c32 !important;
    background-color: #fff !important;
  }
}
.bg-green {
  background-color: #008a00 !important;
}
.text-green {
  color: #008a00 !important;
}


.h-75px {
  height: 75px !important;
}
.glossary-search-popover{
  max-height: 300px;
  overflow: auto;
}
.overflow-break-word {
  overflow-wrap: break-word;
}
.mentor-desc {
  overflow-wrap: anywhere;
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}
.ratings-subtitle {
  font-size: 12px;
  font-weight: 500;
}

.bi-back::before {
  content: "\f155";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.custom-popover-graphic-form {
  width: 350px !important;
}

.alert-success {
  color: #155724;
  background-color: #e5efe8;
  border-color: #c3e6cb;
}


.accordian-card-custom {
  .accordion-item {
    border-radius: 0px !important;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-radius: 0px !important;

  }
  .accordion-item:first-of-type .accordion-button {
    border-radius: 0px !important;

  }
}


.h-75vh {
  height: 75vh !important;
}

.max-h-75vh {
  max-height: 75vh !important;
}
.filter-modal {
  .modal-content {
    height: 100%;
  }
}

.custom-accordian-button {
  @extend .accordion-button;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-bottom: 1px solid #eff2f5;
  cursor: pointer;
}

.overflow-y-auto {
  overflow-y: auto;
}
.border-bottom{
  border-bottom: 1px solid #f5f5f5;
}
.no-wrap {
  text-wrap: nowrap;
}
.bi-chevron-right::before {
  content: "\f285";
}

.badge-custom-search-page {
  background: transparent !important;
  border: 1px solid #bfbfbf !important;
}


.max-w-140px {
  max-width: 140px;
}

.max-w-800px {
  max-width: 800px;
}


.text-align-right {
  text-align: right;
}

.bi-arrow-clockwise::before {
  content: "\f116";
}


.bg-body  {
  background-color: #f3f6f9;
}

.btn-green {
  background-color: #188f44 !important;
}
.btn-green:hover {
  background-color: #0c642c !important;
}
.bi-arrow-repeat::before {
  content: "\f130";
}


.text-yellow {
  color: #ffc107!important;
}
.text-blue {
  color: #007bff!important;
}

.bg-yellow {
  background-color: #ffc107!important;
}
.bg-blue {
  background-color: #007bff!important;
}
.bg-red {
  background-color: #Dc2626 !important;
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1.2rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F5F8FA;
  border-radius: 6px;
  box-shadow: none;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--color-theme);
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.bi-info-circle::before {
  content: "\f431";
}
 .meeting-time-select{

  .ng-select-container {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color 0.2s ease, background-color 0.2s ease;
    height: 42.94px !important;
  }
 }

 .badge-green {
  background-color: green;
}
.badge-red {
  background-color: #Dc2626;
}
.bi-arrows-fullscreen::before {
  content: "\f14d";
}


.custom-full-screen-pitch {
  .modal-dialog {
    height: 83% !important;
    max-width: 83vw !important;
    .modal-content{
      height: 100% !important;
    }
  }

}
.bi-hand-thumbs-up::before {
  content: "\f407";
}
.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}
.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}
.flex-wrap {
  flex-wrap: wrap;
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}
.atcb-button-wrapper {
  padding: 0px !important;
  margin: 0px !important;
}
.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}
.meeting-preview-message {
  .btn {
    border: 1px solid !important;
  }
}

.scroll-100vh {
  height: 100vh;
  overflow: auto;
}
.accordian-button-sidebar {
  .accordion-item{
    border: none;
    margin-bottom: 5px;
  }
  .accordion-button {
    padding: 0.75rem 1rem !important;
    border: none;

    &:hover {
      color: #fff;
      background-color: var(--color-theme);
    }
  }

  .accordion-body  {
    padding: 0.5rem 0;
  }

  &.active {
    .accordion-item {
      border: 1px solid  var(--color-theme);
    }
    .accordion-button {
        color: #fff;
        background-color: var(--color-theme);
      }
  }
}
.protected-sidebar {
  border-right: 1px solid #eee;
  .nav-pills .nav-item:last-child {
    margin-right: 0.5rem;
  }

  .accordion-button:not(.collapsed):after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzE4MUMzMic+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+);
  }

  .btn-group {
    width: 100%;
    .btn  {
      border:  1px solid #eee !important;
      color: black;
    }

    .btn-outline-secondary:hover {
      background-color: var(--color-theme);
      opacity: 1;
      color: black;
    }
  }
}
.bg-hover-user-profile {
  &:hover {
    opacity: 0.9;
  }
}

.user-profile-menu {
  background-color: var(--color-theme) !important;
  color: white !important;
}
.sidebar-profile {
  .user-profile-menu {
    flex-direction: row-reverse;
    justify-content: start !important;
    color: black !important;
    background-color: white !important;
    .profile-name {
      align-items: start !important;
      margin-left: 12px;
    }
  }
  .dropdown-toggle::after {
    display: none !important;
  }
}
.sidebar-dark {
  background-color: var(--color-theme) !important;

  .nav-link {
    color: white !important;

    &.active {
      color: black !important;
      background-color: var(--color-secondary);
    }
  }

  .user-profile-menu {
    background-color: var(--color-theme) !important;
    color: white !important;
  }

  .accordian-button-sidebar {
    .accordion-item{
      border: none;
      margin-bottom: 5px;
      background-color: var(--color-secondary);
    }
    .accordion-button {
      background-color: var(--color-theme);
      color: white;
      padding: 0.75rem 1rem !important;
      border: none;

      &:hover {
        color: black;
        background-color: var(--color-secondary);
      }
    }

    .accordion-body  {
      padding: 0.5rem 0;
    }

    &.active {
      .accordion-button {
          color: black;
          background-color: var(--color-secondary);
        }
    }

  }
  .bi {
    color: white ;
  }

  .btn-group {
    .btn  {
      color: white;
    }

    .btn-outline-secondary:hover {
      color: white;
    }
  }
}
.bi-box-arrow-right::before {
  content: "\f1c3";
}
.sidebar-light {
  .btn-group {
    .btn-outline-secondary:hover {
      color: white;
      .bi {
          color: white !important;
      }
    }
  }
  .header-nav-icon {
    &:hover {
      i {
        color: white !important;
      }
    }
  }

  .nav-link {
    &.active {
      i {
        color: #fff;
      }
    }
  }

  .accordian-button-sidebar {
    &.active {
      i {
        color: #fff;
      }
    }
  }

}

.h-400px {
  height: 400px;
}

.h-95vh {
  height: 95vh;
}
.h-97vh {
  height: 97vh;
}
.community-feed-list {
  // max-height: calc(100vh + 300px) !important;
  // overflow: auto;
}
.bi-tools::before {
  content: "\f5db";
}
.bi-book::before {
  content: "\f194";
}
.bi-search::before {
  content: "\f52a";
}
.bi-people::before {
  content: "\f4d0";
}
.bi-house::before {
  content: "\f425";
}
.bi-person::before {
  content: "\f4e1";
}

.bi-facebook::before {
  content: "\f344";
}
.bi-linkedin::before {
  content: "\f472";
}

.bi-twitter::before {
  content: "\f5ef";
}
.bi-x-circle::before {
  content: "\f623";
}
.bi-youtube::before {
  content: "\f62b";
}
.bi-instagram::before {
  content: "\f437";
}
.bi-person-circle::before {
  content: "\f4d7";
}
.bg-startup-pitch {
  background-size: cover;
  filter: blur(10px);
  -webkit-filter: blur(10px);


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #ccc;

}
.pitch-show-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    // text-shadow: white;
    text-shadow: 0px 0px 2px white;
  }
}
.bi-box-arrow-up-right::before {
  content: "\f1c5";
}
.custom-swal-notification {
  .btn {
    margin: 0px 0px 0 !important;
    margin-top: 5px !important;
    padding: 6px 12px !important;
    width: fit-content;
  }

}
.bi-list-check::before {
  content: "\f473";
}
// .swal2-container {
//   width: 400px !important;
// }

.feed-form {
  .ql-editor {
    height: 200px;
  }
}
.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-calendar3::before {
  content: "\f214";
}

.truncate-2-line {
  // width: 250px;
  // height: 30px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: -webkit-box;
  min-height: 38px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bi-play-fill::before {
  content: "\f4f4";
}
.w-fit {
  width: fit-content;
}
.desc-tooltip {
  width: 500px !important;
}

.bg-transparent {
  background-color: transparent;
}


.border-dashed-large {
  border-style: dashed !important;
  border-color: #373636;
  border-width: 2px !important;
}


.border-end{
  border-right: 1px solid #f5f5f5;
}
.swal2-input-label {
  font-size: 24px;
}

.scroll-y {
  overflow-y: scroll;
  position: relative;
}

@media (max-width: 991.98px) {
  .scroll-y {
    overflow-y: auto;
  }
}

.scroll-sidebar {
  overflow-y: hidden  ;
  &:hover {
    overflow-y: auto;
  }

}
@media (min-width: 992px) {
  .h-lg-auto {
    height: auto !important;
  }
}
.h-300px {
  height: 300px !important;
}

.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}
.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -10px;
  border: 2px solid #ffffff;
  transition: all 0.3s ease;
}
.symbol-group .symbol:hover {
  transition: all 0.3s ease;
  z-index: 1;
}
.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}
.bi-upload::before {
  content: "\f603";
}
.bi-reply::before {
  content: "\f520";
}
.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}
.mw-lg-450px {
  max-width: 450px;
}
.deleted-message{
  i {
    color: rgb(141, 118, 1);
  }
  span {
    font-style: italic;
    color: #929292;
  }
}
.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}
.invisible {
  visibility: hidden;
}


.scroll-y-auto {
  overflow-y: auto;
}
.rounded-0 {
  border-radius: 0;
}
.min-w-400 {
  min-width: 400px;
}
.min-w-230 {
  min-width: 230px;
}
.w-300px  {
  width: 300px;
}
.w-310px  {
  width: 310px;
}

@media only screen and (min-width: 375px) {
  .w-sm-360px {
    width: 360px !important;
  }
}



.edit-grp-class {
  width: 140px;
}
emoji-picker  {
  transform: none !important;
  left: -258px;
  bottom: 0;
}
.fa-mail-bulk:before {
  content: "\f674";
}
.padding-dash-header{
  padding: 10px !important;
  min-height: 70px;
}
.min-w-200px {
  min-width: 200px;
}
.w-120px {
  width: 120px;
}
.pt-15 {
  padding-top: 3.5rem;
}
@media screen and (max-width: 600px) {
  #slides {
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }

  .header {
    background-color: white !important;

    .bi{
      color: rgb(125, 125, 125) !important;
    }
    .bg-hover-user-profile{
      background-color: white !important;
      color: black !important;
    }
    .svg-icon{
      color: black !important;
    }
  }
}
@media only screen and (min-width: 767px) {
  .min-w-md-400 {
    min-width: 400px !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .align-items-md-end{
    align-items: end !important;
  }
  .text-align-md-right {
    text-align: right !important;
  }
  .min-w-450 {
    width: 450px !important;
  }
  .padding-dash-header{
    padding: 15px 30px !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-fit {
    width: fit-content !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .pt-md-10 {
    padding-top: 2.5rem !important;
  }
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.fa-hands-helping:before {
  content: "\f4c4";
}


.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-download:before {
  content: "\f019";
}
.custom-avatar-program {
  margin-top: 10px;
  margin-bottom: 10px;
  .avatar-content{
    color: #fff !important;
    background: var(--color-theme) !important;
    border-radius: 8px !important;
  }
}
.bi-check::before {
  content: "\f26e";
}
.custom-avatar-comm-feed{
  .avatar-content {
    color: white !important;
  }
}


.bg-light-gray-200{
  background-color: #eff2f5;
}

.bg-light-gray-200-imp{
  background-color: #eff2f5 !important;
}


.certi-modal {
  height: 800px !important;
  max-height: unset !important;

  width: 1200px !important;
  max-width: unset !important;
}

.g-5,.gx-5 {
    --bs-gutter-x: 1.25rem !important;
    --bs-gutter-y: 1.25rem !important;
  }


@media print {
  .print-growth {
    width: 100% !important;
  }
  .page-break {
    page-break-after: always; /* Ensure each element with this class starts on a new page */
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.ip-req-msg-box{
  textarea{
    height: 220px;
  }
}
.circle-check-metrics {
  &::before{
    content: "\f26a";
    background: white;
    border-radius: 200px;
    height: 16px;
    width: 16px;
    /* padding-left: 0px; */
    left: 2px;
    left: 0px;
    /* position: absolute; */
  }
}


.bi-shield-lock::before {
  content: "\f538";
}


.show-rating-booking {
  ngx-star-rating{
    width: fit-content;
  }
}

.custom-select-booking {
  width: 200px !important;
}

i.socialIcon{
  font-size: 25px;
  margin-right: 15px;
  &:hover {
    color:  var(--color-theme) !important;
  }
}
.partner-ng-select {
  // &:disabled {
    background-color: #f9f9f9 !important;
  // }
}


.shadow-none {
  box-shadow: none !important;
}
@media only screen and (max-width: 767px) {
  .custom-avatar-selectedProgram {
    .avatar-container {
      height: 50px !important;
      width: 50px !important;
    }
    .avatar-content {
      height: 50px !important;
      width: 50px !important;
      font: 30px Arial !important;
      display: flex;

      justify-content: center;
      align-items: center;
    }
    // height: 50px !important;
    width: 50px !important;
  }
}
.market-insight-frames {
  min-height: 500px !important;
  height: calc(100vh - 300px);
  width: 100%;
}


body [loaderid="connect-button"]{
  // position: fixed !important;

  .ngx-position-absolute{
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    height: 100vh !important;
    width: 100% !important;
    z-index: 10000000000 !important;
  }
  .ngx-overlay{
    position: fixed !important;
  }

}

// .ngx-position-absolute {
//   position: fixed !important;
// }

// ngx-overlay ngx-position-absolute
.lg-container {
  z-index: 10000000;
}


.bi-info-square-fill::before {
  content: "\f432";
}
.square-fill-info-custom {
  &::before {
    font-size: 25px;
    color: #a1a5b7;
    // margin-right: 15px;
  }
  &:hover {
    &::before {
      color:  var(--color-theme) !important;
    }
  }
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.partner-people-slider {
  .swiper-wrapper {
    display: flex !important;
  }
  .swiper-pagination {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    gap: 10px;

  }
  .swiper-pagination-bullet {
    height:10px;
    width:10px;
    display:block;
    background: #b6b6b6;
    border-radius: 10px;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    background: #7c7c7c;
  }

}
.word-break-all {
  word-break: break-all;
}
.ql-editor.ql-blank:before {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  // color: #5e6278;
  color: #a1a5b7 !important;
  opacity: 1;
}

.profile-edit-footer {
  position: sticky;
  bottom: 0;
  background-color: white !important;
}


.profile-edit-extra-footer {
  padding: 15px 0px;
  border-top: 1px solid #eff2f5;
  // position: sticky;
  // bottom: 0;
  // background-color: white !important;
}


.input-grp-icon {
  border-radius: 0.75rem 0px 0 0.75rem;
  display: flex;
  justify-content: center;
  align-items:center;
  .socialIcon{
    margin-right: 0px !important;
  }
}


.static-header-profile-edit {
  position: sticky;
    top: 0;
    z-index: 1000;
}

.custom-phone-no-field {
  width: 100%;
  display: flex;

}

.iti {
  display: flex !important;
}
.iti--allow-dropdown {
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: ;
}
.iti__flag-container{
  // padding: ;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f8fa;
  border: 1px solid #e4e6ef;
  color: #3f4254;
  padding: 0.90rem 1rem;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  height: 42px;
}
.iti__arrow {
  // visibility: hidden;

  top: 20.5px;
  display: block;
  position: absolute;
  left: 26px;

}
.iti__flag{
  margin-top: 2px;
}
.custom-phone-no-field{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.country-dropdown{
  height: 150px;
  overflow: auto;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 12px !important;
  .search-container {
    padding: 8px 0px !important;
  }
}
.bi-chat-quote-fill::before {
  content: "\f254";
}
.profile-view-tooltip {
  background-color: red !important;
}
.otp-program{
  .ng-otp-input-wrapper{
    justify-content: start;
  }
}

.bi-twitter-x::before {
  content: "\F8DB";
}


.twitter-x-icon  {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;

  // svg {

  // }
}
